




















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ComponentName } from '@/constants/enums/componentName';
import { SelectOption } from '@/constants/interfaces/SelectOption';

@Component({
    name: ComponentName.SELECT_FIELD,
    components: {},
})
export default class SelectFieldComponent extends Vue {
    @Prop() private autocomplete!: string;
    @Prop() private label!: string;
    @Prop() private name!: string;
    @Prop({ default: '' }) private placeholder!: string;
    @Prop({ default: [] }) private options!: SelectOption[];
    @Prop({ default: false }) private disabled!: boolean;
    @Prop({ default: false }) private required!: boolean;
    @Prop({ default: null }) private selected!: SelectOption | null;

    private selectedOption = this.selected;

    private get isEmpty(): boolean {
        return !this.selectedOption && this.placeholder === '';
    }

    private onChange(): void {
        this.$emit('change', this.selectedOption);
    }

    private get labelVal(): string {
        return this.required ? `${this.label}*` : this.label;
    }

    @Watch('selected', { immediate: true })
    private onValueChanged(val: SelectOption) {
        this.selectedOption = this.options.find((option) => option === val) || null;
    }
}
