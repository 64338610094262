




















import { Vue, Component, Prop } from 'vue-property-decorator';
import { ComponentName } from '@/constants/enums/componentName';

@Component({
    name: ComponentName.INPUT_FIELD,
    components: {},
})
export default class InputFieldComponent extends Vue {
    @Prop() private autocomplete!: string;
    @Prop() private label!: string;
    @Prop() private maxlength!: number | null;
    @Prop() private minlength!: number | null;
    @Prop() private value!: string | null;
    @Prop({ default: '' }) private placeholder!: string;
    @Prop({ default: 'input' }) private name!: string;
    @Prop({ default: 'text' }) private type!: string;
    @Prop({ default: false }) private disabled!: boolean;
    @Prop({ default: false }) private error!: boolean;
    @Prop({ default: false }) private readonly!: boolean;
    @Prop({ default: false }) private required!: boolean;

    private get isEmpty(): boolean {
        return (this.value === '' || this.value === undefined || this.value === null) && this.placeholder === '';
    }

    private get labelVal(): string {
        return this.required ? this.label + '*' : this.label;
    }

    private get inputVal(): string | null {
        return this.value !== null ? this.value : '';
    }

    private set inputVal(val: string | null) {
        this.$emit('input', val);
    }
}
