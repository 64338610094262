

























































import { Component, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/constants/enums/routeNames';
import ContentRow from '@/components/contentRow.vue';
import ContentCard from '@/components/contentCard.vue';
import callcenterSvg from '@/../public/img/callcenter.svg?inline';
import conversationSvg from '@/../public/img/conversation.svg?inline';
import headsetSvg from '@/../public/img/headset.svg?inline';
import Btn from '@/components/btn.vue';

@Component({
    name: RouteNames.CONSULTATION,
    components: {
        Btn,
        ContentCard,
        ContentRow,
        callcenterSvg,
        conversationSvg,
        headsetSvg,
    },
})
export default class Consultation extends Vue {
    private goToContact(): void {
        this.$router.push({
            name: RouteNames.CONTACT,
        });
    }
}
