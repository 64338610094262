












import { Vue, Component, Prop } from 'vue-property-decorator';
import { ComponentName } from '@/constants/enums/componentName';
import { NavigationItem } from '@/constants/interfaces/Navigation';

@Component({
    name: ComponentName.NAVIGATION,
    components: {},
})
export default class NavigationComponent extends Vue {
    @Prop() private items!: NavigationItem[];
    @Prop({ default: false }) private hasActiveBorder!: boolean;
    @Prop({ default: false }) private isTopDown!: boolean;
}
