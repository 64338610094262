











import { Vue, Component, Prop } from 'vue-property-decorator';
import { ComponentName } from '@/constants/enums/componentName';

@Component({
    name: ComponentName.CONTENT_ROW,
    components: {},
})
export default class ContentRowComponent extends Vue {
    @Prop({ default: false }) private isFullWidth!: boolean;
}
