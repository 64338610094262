









import { Vue, Component } from 'vue-property-decorator';
import { ComponentName } from '@/constants/enums/componentName';
import Btn from '@/components/btn.vue';

@Component({
    name: ComponentName.COOKIE_BANNER,
    components: { Btn },
})
export default class CookieBannerComponent extends Vue {
    private isActive = false;
    private storageName = 'cookie:accepted';

    private get getVisited() {
        let visited = localStorage.getItem(this.storageName);
        if (typeof visited === 'string') {
            visited = JSON.parse(visited);
        }
        return !(visited === null || visited === undefined);
    }

    private setVisited() {
        this.isActive = false;
        localStorage.setItem(this.storageName, 'true');
    }

    private mounted() {
        if (!this.getVisited) {
            this.isActive = true;
        }
    }
}
