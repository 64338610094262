import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMapMarkerAlt, faMobileAlt, faEnvelope, faCaretRight, faBars, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Actions } from '@/constants/enums/store';
import VueScrollTo from 'vue-scrollto';
import UniqueId from 'vue-unique-id';
import Notifications from 'vue-notification';

Vue.config.productionTip = false;

library.add(faMapMarkerAlt, faMobileAlt, faEnvelope, faCaretRight, faBars, faSpinner, faTimes);
Vue.component('fa-icon', FontAwesomeIcon);
Vue.use(VueScrollTo, {
    duration: 800,
    easing: [0.75, 0, 0.22, 1],
});
Vue.use(UniqueId);
Vue.use(Notifications);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');

router.beforeEach((to, from, next) => {
    store.dispatch(Actions.setRoute, to).then(() => {
        next();
    });
});

router.afterEach(() => {
    VueScrollTo.scrollTo('header');
});
