


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { ComponentName } from '@/constants/enums/componentName';
@Component({
    name: ComponentName.MODAL,
    components: {},
})
export default class ModalComponent extends Vue {
    @Prop({ default: false }) private isActive!: boolean;
    @Prop({ default: '' }) private headline!: string;

    private mounted() {
        document.addEventListener('keydown', this.onKeydown, true);
    }

    private beforeDestroy() {
        document.removeEventListener('keydown', this.onKeydown, true);
    }

    private onCloseModal() {
        this.$emit('close');
    }

    private onKeydown(e: KeyboardEvent) {
        if (e.key === 'Escape') {
            this.onCloseModal();
        }
    }
}
