import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Route } from 'vue-router';
import { Actions, Getters, Mutations } from '@/constants/enums/store';

@Module
export default class RouteStore extends VuexModule {
    private route: Route | null = null;

    public get [Getters.currentRoute](): Route | null {
        return this.route;
    }

    @Mutation
    public [Mutations.saveRoute](route: Route): void {
        if (route) {
            this.route = route;
        }
    }

    @Action({ commit: Mutations.saveRoute })
    public [Actions.setRoute](payload: Route): Route {
        return payload;
    }
}
