






import { Vue, Component, Prop } from 'vue-property-decorator';
import { ComponentName } from '@/constants/enums/componentName';

@Component({
    name: ComponentName.CONTENT_CARD,
    components: {},
})
export default class ContentCardComponent extends Vue {
    @Prop({ default: false }) private hasFullBorder!: boolean;
}
