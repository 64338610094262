















import { Vue, Component, Prop } from 'vue-property-decorator';
import { ComponentName } from '@/constants/enums/componentName';

@Component({
    name: ComponentName.CHECKBOX_FIELD,
    components: {},
})
export default class checkboxComponent extends Vue {
    @Prop({ default: false }) private disabled!: boolean;
    @Prop({ default: false }) private isError!: boolean;
    @Prop({ default: false }) private readonly!: boolean;
    @Prop({ default: false }) private required!: boolean;
    @Prop() private label!: string;
    @Prop({ default: 'checkbox' }) private name!: string;
    @Prop({ default: '' }) private placeholder!: string;
    @Prop({ default: false }) private value!: boolean;

    private get labelVal(): string {
        return this.required ? this.label + '*' : this.label;
    }

    private get inputVal(): boolean {
        return this.value;
    }

    private set inputVal(val: boolean) {
        this.$emit('input', val);
    }
}
