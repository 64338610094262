/**
 * @enum ComponentName
 * Declares available component names.
 */
export enum ComponentName {
    BOTTOM_FOOTER = 'BottomFooter',
    BTN = 'Btn',
    CHECKBOX_FIELD = 'CheckboxField',
    CONTENT_CARD = 'ContentCard',
    CONTENT_ROW = 'ContentRow',
    COOKIE_BANNER = 'CookieBanner',
    HEADER_MOBILE = 'HeaderMobile',
    INPUT_FIELD = 'InputField',
    MODAL = 'Modal',
    NAVIGATION = 'Navigation',
    SELECT_FIELD = 'SelectField',
    TEXT_AREA = 'TextArea',
    TOP_HEADER = 'TopHeader',
}
