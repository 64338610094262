










































import { Component, Vue } from 'vue-property-decorator';
import TopHeader from '@/components/topHeader.vue';
import BottomFooter from '@/components/bottomFooter.vue';
import HeaderMobile from '@/components/headerMobile.vue';
import { isMobile } from 'mobile-device-detect';
import CookieBanner from '@/components/cookieBanner.vue';

@Component({
    components: {
        CookieBanner,
        HeaderMobile,
        BottomFooter,
        TopHeader,
    },
})
export default class App extends Vue {
    private isMobile = isMobile;
}
