/**
 * @enum Actions
 * Declares available actions for the store.
 */
export enum Actions {
    setRoute = 'setRoute',
}

/**
 * @enum Getters
 * Declares available getters for the store.
 */
export enum Getters {
    currentRoute = 'currentRoute',
}

/**
 * @enum Mutations
 * Declares available mutations for the store.
 */
export enum Mutations {
    saveRoute = 'saveRoute',
}
