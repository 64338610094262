







import { Vue, Component, Prop } from 'vue-property-decorator';
import { ComponentName } from '@/constants/enums/componentName';

@Component({
    name: ComponentName.BTN,
    components: {},
})
export default class BtnComponent extends Vue {
    @Prop({ default: 'button' }) private type!: string;
    @Prop({ default: false }) private isLoading!: boolean;
    @Prop({ default: false }) private disabled!: boolean;

    private onClick(event: MouseEvent): void {
        if (!this.disabled || !this.isLoading) {
            this.$emit('click', event);
        }
    }
}
