export interface ContactForm {
    company: string;
    consent: boolean;
    hpot1: string;
    hpot2: string;
    mail: string;
    message: string;
    name: string;
    phone: string;
    preferredContact: PreferredContact | null;
    salutation: Salutation | null;
}

export enum Salutation {
    HERR = 'Herr',
    FRAU = 'Frau',
    DIVERS = 'Divers',
}

export enum PreferredContact {
    MAIL = 'E-Mail',
    PHONE = 'Telefon',
}
