














































































import { Component, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/constants/enums/routeNames';
import ContentRow from '@/components/contentRow.vue';
import ContentCard from '@/components/contentCard.vue';
import { isMobile } from 'mobile-device-detect';

@Component({
    name: RouteNames.IMPRESS,
    components: {
        ContentCard,
        ContentRow,
    },
})
export default class Impress extends Vue {
    private isMobile = isMobile;
}
