/**
 * @enum RouteNames
 * Declares route names.
 */
export enum RouteNames {
    ABOUT_ME = 'AboutMe',
    CONSULTATION = 'Consultation',
    CONTACT = 'Contact',
    HOME = 'Home',
    IMPRESS = 'Impress',
    PRIVACY_POLICY = 'PrivacyPolicy',
    SERVICE = 'Service',
}
